import React, { useEffect, useState,useRef } from "react";
import { CSmartTable } from "@coreui/react-pro";
import {
  CBadge,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CFormSwitch,
} from "@coreui/react";
import { useProductListingContext } from "../context/productListingContext";
import BarcodeGenerator from "../pluginComponents/generateBarcode";
import temp_image from "../images/adbc.png";
import { MdPreview } from "react-icons/md";
import { saveAs } from 'file-saver';
import {
  BsFillEmojiSunglassesFill,
  BsFillEmojiFrownFill,
} from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { ImBarcode, ImQrcode } from "react-icons/im";
import { usePDF } from 'react-to-pdf';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';
// import { RiFileExcel2Fill } from "react-icons";
import {
  CRow,
  CCol,
  CContainer,
  CCard,
  CCallout,
  CWidgetStatsC,
  CWidgetStatsF,
  CCardBody,
  CFormCheck,
  CFormSelect,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CSpinner,
} from "@coreui/react";
import GenerateExcel from "../pluginComponents/generateExcel";
import { listedProducts } from "../services/generalService";
import { dayMonth, packaging } from "./../fakedata/packaging";
import { parseISO, formatDistanceToNow } from "date-fns";
import Select from "react-select";
import { targetMarket } from "../fakedata/market";
import { useUserContext } from "../context/userContext";
import { generateBarcode } from "../services/generalService";
import { toast } from "react-toastify";
import unitbottle from "../components/barcodecomponent/images/unitbottle.png";
import pack from "../components/barcodecomponent/images/pack.png";
import pallet from "../components/barcodecomponent/images/pallet.png";
import cases_img from "../components/barcodecomponent/images/case.png";
import mixed_img from "../components/barcodecomponent/images/mixed.png";
import mixed_pallet from "../components/barcodecomponent/images/mixedpallet.png";
import noimg from "../images/adbc.png";
import axios from "axios";
import { set } from "lodash";

const ViewProducts = () => {
  const [loading, setLoading] = useState(false);
  const [format, setformat] = useState(0);
  const {
    packaginglevel_id,
    products,
    dispatch,

    thisGtin,
    lifespanunit,
    lifespan,
    packagingtype_id,
    handleChange,
    loadPackaging,
    loadPackageType,
    TargetMarketList,
    bricks,
    showBarcode,
    brickcategory_id,
    handleSelect,
    nafdacnumber,
    tradeItemDescription,
    manufacturer,
    manufacturerAddress,
    strength,
    productForm,
    routeOfAdministration,
    packSize,
    quantity,
    shelfLife,
    ActiveIngredient,
    directionOfUse,
    downloadBarcode,
    productdescription,
    marketingmessage,
    gtinfetch,
    netweight,
    ingredients,
    netcontent_id,
    units,
    FrontImage,
    directionofuse,
    storageinstruction,
    genericName,
    functionalName,
    atcCode,
    BackImage,
    dispImg1,
    dispImg2,
    handlePackaging,
    targetedMarket,

    consumerfirstavailabilitydate,
    brandinformation_id,
    brands,
  } = useProductListingContext();
  const { registrationid } = useUserContext();
  const [visibleMM, setVisibleMM] = useState(false);
  const [visibleM, setVisibleM] = useState(false);
  const [visibleMMM, setVisibleMMM] = useState(false);
  const [productToupdate, setProductToUpdate] = useState("");
  const [productToView, setProductToView] = useState("");
  const [fState, setfState] = useState(1);
  const [dayOrWeek, setDayOrWeek] = useState(dayMonth);
  const [manualPharma, setManualPharma] = useState(false);
  const [targetMarketList, setTargetMarketList] = useState([]);

  const [qrdata, setQRData] = useState('');
  const qrCodeRef=React.createRef();
  const [qrmodal, setQRModal] = useState(false);
  const [qrgtin, setQRGtin] = useState("");


  const [show, setShow] = useState({
    showImg: noimg,
    frnt: "",
    bck: "",
  });
  let useThisDate = "";
  let sortDate;
  let activeI = "";
  let genericN = "";
  let atcC = "";
  let lifeS = "";
  let funcN = "";
  let pacS = "";
  let routeOfA = "";
  let pForm = "";
  let tradeItemDes = "";
  let manuf = "";
  let manufAddr = "";
  let ProdStrength = "";
  let shelfL = "";
  let pharmQty = "";
  let dirOfUse = "";
  activeI = "";

  let dateFixed = "";
  if (productToupdate !== "") {
    dateFixed = productToupdate?.dateoflisting;
    sortDate = dateFixed?.split("T");
    useThisDate = sortDate[0];
  }

  if (productToupdate !== "" && productToupdate.isPharma !== false) {
    dirOfUse = productToupdate?.pharmaceuticalInformation?.directionOfUse;
    activeI = productToupdate?.pharmaceuticalInformation?.activeIngredient;
    genericN = productToupdate?.pharmaceuticalInformation?.genericName;
    atcC = productToupdate?.pharmaceuticalInformation?.atcCode;
    lifeS = productToupdate?.pharmaceuticalInformation?.shelfLife;
    funcN = productToupdate?.pharmaceuticalInformation?.functionalName;
    pacS = productToupdate?.pharmaceuticalInformation?.packSize;
    routeOfA =
      productToupdate?.pharmaceuticalInformation?.routeOfAdministration;
    pForm = productToupdate?.pharmaceuticalInformation?.productForm;
    tradeItemDes =
      productToupdate?.pharmaceuticalInformation?.tradeItemDescription;
    manuf = productToupdate?.pharmaceuticalInformation?.manufacturer;
    manufAddr = productToupdate?.pharmaceuticalInformation?.manufacturerAddress;
    ProdStrength = productToupdate?.pharmaceuticalInformation?.strength;
    shelfL = productToupdate?.pharmaceuticalInformation?.shelfLife;
    pharmQty = productToupdate?.pharmaceuticalInformation?.quantity;
  }

  //console.log("product to update", productToupdate);
  const targeted_market = targetedMarket.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.name;

    return container;
  });

  const listAsHealthCare = () => {
    if (manualPharma == false) {
      setManualPharma(true);
    } else if (manualPharma == true) {
      setManualPharma(false);
    }
    dispatch({ type: "LIST_AS_HEALTHCARE" });
  };




  useEffect(() => {
    const myListedProducts = async () => {
      try {
        dispatch({ type: "LOADING_PRODUCT" });
        const data = (await listedProducts(registrationid)).data;
        //console.log("Data on listed products", data);
        dispatch({ type: "PRODUCT_LOADED", payload: data.returnedObject });
      } catch (error) {
        toast.warn("Please retry after some time");
      }
    };
    if (registrationid != undefined) {
      myListedProducts();
    }

  }, [visibleMM, visibleM]);

  useEffect(() => {
    if (packaginglevel_id == "") {
      dispatch({
        type: "HANDLE_PACKAGING",
        payload: productToupdate.packaginglevel_id,
      });
    }
    dispatch({ type: "HANDLE_PACKAGING", payload: packaginglevel_id });
  }, [packaginglevel_id, productToupdate.packaginglevel_id]);
  // console.log("date fixed", sortDate[0]);

  const saveFrontImage = (e, fieldname) => {
    const [file] = e.target.files;
    const getUrl = e.target.files[0];
    const getUrlshw = URL.createObjectURL(file);
    // setImg(URL.createObjectURL(file));
    dispatch({
      type: "IMAGE_GET",
      payload: { getUrl, getUrlshw },
    });
    setShow({ ...show, frnt: getUrlshw });
    //console.log("show", show.frnt);
  };

  const saveBackImg = (e, fieldname) => {
    const [file] = e.target.files;
    const getUrlshw = URL.createObjectURL(file);
    const getUrl = e.target.files[0];
    // console.log(e.target.files[0])

    // setImg(URL.createObjectURL(file));
    dispatch({
      type: "BACKIMAGE_GET",
      payload: { getUrl, getUrlshw },
      // value: { a: getUrl, b: getUrlshw },
      // name: fieldname,
    });
    setShow({ ...show, bck: getUrlshw });
  };
  const handleTargetMarket = (e, det) => {
    // console.log("de", e);
    //console.log("market", e);
    var a = Array.isArray(e) ? e.map((x) => x.value) : [""];
    dispatch({
      type: "UPDATE_MARKET",
      value: a,
      name: det,
    });
  };
  const handleFormatRadioChange = (event) => {
    console.log(event.target.value)
    setformat(event.target.value);
  };
  const saveEditedInfo = async () => {
    let formdata = new FormData();
    netcontent_id == ""
      ? formdata.append("Product.netcontent_id", productToupdate.netcontent_id)
      : formdata.append("Product.netcontent_id", netcontent_id);

    brandinformation_id == "--select--"
      ? formdata.append("Product.brandinformation_id", productToupdate.brandinformation_id)
      : formdata.append("Product.brandinformation_id", brandinformation_id);

    formdata.append("registrationid", registrationid);
    formdata.append("productID", productToupdate.id);
    consumerfirstavailabilitydate == ""
      ? formdata.append(
        "Product.consumerfirstavailabilitydate",
        productToupdate.consumerfirstavailabilitydate
      )
      : formdata.append(
        "Product.consumerfirstavailabilitydate",
        consumerfirstavailabilitydate
      );
    productdescription == ""
      ? formdata.append(
        "Product.productdescription",
        productToupdate.productdescription
      )
      : formdata.append("Product.productdescription", productdescription);

    packagingtype_id == ""
      ? formdata.append(
        "Product.packagingtype_id",
        productToupdate.packagingtype_id
      )
      : formdata.append("Product.packagingtype_id", packagingtype_id);
    netweight == ""
      ? formdata.append("Product.netweight", productToupdate.netweight)
      : formdata.append("Product.netweight", netweight);
    brickcategory_id == ""
      ? formdata.append(
        "Product.brickcategory_id",
        productToupdate.brickcategory_id
      )
      : formdata.append("Product.brickcategory_id", brickcategory_id);
    // formdata.append(
    //   "Product.brandinformation_id",
    //   parseInt(brandinformation_id)
    // );
    packaginglevel_id == ""
      ? formdata.append(
        "Product.packaginglevel_id",
        productToupdate.packaginglevel_id
      )
      : formdata.append("Product.packaginglevel_id", packaginglevel_id);
    formdata.append("Product.ingredients", ingredients);
    formdata.append("Product.storageinstruction", storageinstruction);
    // formdata.append("Product.directionofuse", directionofuse);
    formdata.append("Product.marketingmessage", marketingmessage);
    formdata.append("Product.lifespan", lifespan);
    formdata.append("Product.lifespanunit", lifespanunit);
    formdata.append("TargetMarketList", TargetMarketList);
    formdata.append("ProductImageUpload.FrontImage", FrontImage);
    formdata.append("ProductImageUpload.BackImage", BackImage);
    formdata.append("Product.nafdacnumber", nafdacnumber);
    //pharmaproducts
    // formdata.append("Product.IsPharma", toggleHealthCare);
    {
      productToupdate.isPharma == "true" || manualPharma
        ? formdata.append("Product.IsPharma", true)
        : formdata.append("Product.IsPharma", false);
    }

    formdata.append("PharmaceuticalInformation.GenericName", genericName);
    formdata.append(
      "PharmaceuticalInformation.TradeItemDescription",
      tradeItemDescription
    );
    formdata.append(
      "PharmaceuticalInformation.Manufacturer",
      manufacturer
    );
    formdata.append(
      "PharmaceuticalInformation.ManufacturerAddress",
      manufacturerAddress
    );
    formdata.append("PharmaceuticalInformation.Strength", strength);
    formdata.append("PharmaceuticalInformation.ProductForm", productForm);
    formdata.append("PharmaceuticalInformation.FunctionalName", functionalName);
    formdata.append(
      "PharmaceuticalInformation.RouteOfAdministration",
      routeOfAdministration
    );
    formdata.append("PharmaceuticalInformation.ATCCode", atcCode);
    formdata.append("PharmaceuticalInformation.PackSize", packSize);
    formdata.append("PharmaceuticalInformation.Quantity", quantity);
    formdata.append("PharmaceuticalInformation.shelfLife", shelfLife);

    productToupdate.isPharma == "true"
      ? formdata.append(
        "PharmaceuticalInformation.primaryPackaginMaterial",
        productToupdate.packagingtype_id
      )
      : formdata.append(
        "PharmaceuticalInformation.primaryPackaginMaterial",
        ""
      );
    formdata.append(
      "PharmaceuticalInformation.ActiveIngredient",
      ActiveIngredient
    );
    formdata.append("PharmaceuticalInformation.DirectionOfUse", directionOfUse);
    try {
      // dispatch({ type: "SAVE_PRODUCTS" });
      setLoading(true);
      await axios({
        url: `${process.env.REACT_APP_BASE_URL}product/updatewithimage`,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formdata,
      })
        .then((res) => {
          if (res.data.isSuccess == true) {
            toast.success("Product edited!");
            setVisibleMM(!visibleMM);
            setLoading(false);
            setShow({ ...show, frnt: "", bck: "" });
            setManualPharma(false);
            dispatch({ type: "CLEAR_STATE" });
          }
          //else
        })
        .catch((er) => {
          toast.warn("Please try again after some time");
          dispatch({ type: "CLEAR_STATE" });
          // dispatch({ type: "SAVED_OR_WHATEVER" });
          setLoading(false);
        });
    } catch (err) {
      toast.warn("Please try again after some time");
      // dispatch({ type: "SAVED_OR_WHATEVER" });
      //console.log("error", err);
      setLoading(false);
    }
  };

  const unitOption = units.map((u) => {
    const container = {};

    container.value = u.id;
    container.id = u.id;
    container.label = u.name;

    return container;
  });

  const brandOption = brands.map((u) => {
    const container = {};

    container.value = u.id;
    container.id = u.id;
    container.label = u.brandname;

    return container;
  });

  const handleDownload = (gtin) => {
    //switch back to axios
    fetch(
      `${process.env.REACT_APP_BASE_URL}imagebank/downloadbarcode?gtin=${gtin}&format=2`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Add any necessary headers for authentication or other requirements
        },
      }
    )
      .then((response) => response.blob()) // Convert the response to a Blob object
      .then((blob) => {
        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob);
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = url;
        if (format == 2) {
          link.setAttribute("download", `${gtin}.wmf`);
        } else {
          link.setAttribute("download", `${gtin}.png`);
        }
        // Set the desired file name and extension
        // Programmatically click the link to trigger the download
        link.click();
        // Clean up the temporary URL and link
        URL.revokeObjectURL(url);
        link.remove();
        // dispatch({ type: "IMAGE_DOWLOADED" });
      })
      .catch((error) => {
        toast.warn("Something went wrong");
      });
  };




  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCodeRef.current.src;
    link.download = 'qrcode.png';
    link.click();
  };
  const getQR = async (gtin) => {
    
    setQRGtin(gtin);
    // const qrCodeRef=useRef(null);

    var baseUrl = "https://productregistry.gs1ng.org/products/product-detail/01/";
    var qrLink = baseUrl + gtin;

    try {
      const qrCode = await QRCode.toDataURL(qrLink);
      setQRData(qrCode);
      setQRModal(true);
      // qrCodeRef.current.src = qrCode;
      // var x=qrCodeRef.current;

      // return (

       
        // <div>
        //     <input type="text" value={data} onChange={(e) => setData(e.target.value)} />
        //     <button onClick={generateQRCode}>Generate QR Code</button>
        //     <img ref={qrCodeRef} alt="QR Code" />
        //     <button onClick={downloadQRCode}>Download QR Code</button>
        // </div>
      // );
    } catch (error) {
      console.error(error);
    }

  }
  const getImages = async (gtin) => {
    setVisibleM(true);
    const body = {
      registrationid: registrationid,
      gtin: gtin,
      format: 0,
    };
    try {
      const data = (await generateBarcode(body)).data;
      // console.log("some images", data);
      toast.info(data.message);
      if (data.isSuccess == true) {
        dispatch({
          type: "BARCODE_IMAGE",
          payload: {
            mainImageLink: data.returnedObject?.mainImageLink,
            displayImageLink: data.returnedObject?.displayImageLink,
            gtin: gtin,
          },
        });
      }
    } catch (er) { }
  };

  const option = loadPackageType.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.name;

    return container;
  });
  const brickmap = bricks.map((i) => {
    const maped = {};
    maped.value = i.id;
    // maped.value = i.brick_id;
    maped.label = i.brick;

    return maped;
  });
  // console.log("brickmap", brickmap)
  console.log("product information", productToupdate);

  const firstDefault = [
    {
      value: "--select--",
      label: "--select a valid option--",
    },
  ];
  //console.log("lll", productToupdate);

  const ModifiedPackage = firstDefault.concat(option);

  let img = "";
  const fetchFaceEmoji = (i) => {
    switch (i) {
      case false:
        return (
          <div className="bg-gs1orange text-white rounded-lg w-[104px] h-9 px-[5px] ">
            <p className="text-center ">Incomplete</p>
          </div>
        );
      case true:
        return (
          <div className="bg-gs1-color-forest-accessible-dark-1 text-white rounded-lg w-[104px] h-8 px-[5px] ">
            <p className="text-white text-center ">completed</p>
          </div>
        );
    }
  };
  // Extract this to a component latter
  // const formatTime = (timestamp) => {
  //   const date = parseISO(timestamp);
  //   const period = formatDistanceToNow(date);
  //   return <td>{period} ago</td>;
  // };
  const callUpdate = (products) => {
    setVisibleMM(true);
    setProductToUpdate(products);
    setManualPharma(false);
    // console.log(products);
  };
  const viewInfo = (products) => {
    setVisibleMMM(true);
    setProductToView(products);
    // setProductToUpdate(products);
    // console.log(products);
  };
  const columns = [
    {
      key: "frontimage",
      isImg: true,
      label: "Image",
      filter: false,
      sorter: false,
    },
    {
      key: "brandname",
      label: "Brand Name",
      filter: false,
    },
    {
      key: "productdescription",
      label: "Description",
      filter: false,
    },

    {
      key: "gtin",
      label: "GTIN",
      filter: false,
    },
    // {
    //   key: "dateoflisting",
    //   label: "Date of listing",
    //   filter: false,
    // },

    {
      key: "iscompleted",
      label: "Status",
      filter: false,
      _style: { width: "1%" },
      sorter: false,
    },
    {
      key: "generate_barcode",
      label: "",
      filter: false,
      sorter: false,
    },
    {
      key: "edit_products",
      label: "",
      filter: false,
      sorter: false,
    },
    {
      key: "view_info",
      label: "",
      filter: false,
      sorter: false,
    },
    {
      key: "download_qr",
      label: "",
      filter: false,
      sorter: false,
    },
  ];
  const rendercard = () => {
    if (packaginglevel_id === 1) {
      return (
        <CCallout color="primary">{loadPackaging[0].description}</CCallout>
      );
    } else if (packaginglevel_id === 2) {
      return (
        <CCallout color="secondary">{loadPackaging[1].description}</CCallout>
      );
    } else if (packaginglevel_id === 3) {
      return (
        <CCallout color="success">{loadPackaging[2].description}</CCallout>
      );
    } else if (packaginglevel_id === 4) {
      return (
        <CCallout color="warning">{loadPackaging[3].description}</CCallout>
      );
    } else if (packaginglevel_id === 5) {
      return <CCallout color="info">{loadPackaging[4].description}</CCallout>;
    } else if (packaginglevel_id === 6) {
      return <CCallout color="dark">{loadPackaging[5].description}</CCallout>;
    } else if (packaginglevel_id === 7) {
      return (
        <CCallout color="success">{loadPackaging[6].description}</CCallout>
      );
    }
  };
  const showThisImage = () => {
    if (packaginglevel_id === 1) {
      return (
        <img
          className="gtin-icons"
          src={unitbottle}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 2) {
      return (
        <img className="gtin-icons" src={pack} style={{ height: 200 }} alt="" />
      );
    } else if (packaginglevel_id === 3) {
      return (
        <img
          className="gtin-icons"
          src={cases_img}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 4) {
      return (
        <img
          className="gtin-icons"
          src={mixed_img}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 5) {
      return (
        <img
          className="gtin-icons"
          src={pallet}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 6) {
      return (
        <img
          className="gtin-icons"
          src={mixed_pallet}
          style={{ height: 200 }}
          alt=""
        />
      );
    }
  };
  let b = "";
  let defaultValue = "";
  let market_show = "";

  if (productToupdate !== "") {
    defaultValue =
      productToupdate.targetMarketList.length > 0
        ? targeted_market.filter((x) =>
          productToupdate.targetMarketList.includes(x.value)
        )
        : null;
  }

  if (TargetMarketList.length > 0) {
    market_show =
      TargetMarketList.length > 0
        ? targeted_market.filter((x) => TargetMarketList.includes(x.value))
        : null;
  }
  // console.log("Shityy", productToupdate.brickcategory_id);
  // productToupdate.targetMarketList.length > 0
  //   ? targeted_market.filter((x) =>
  //       productToupdate.targetMarketList.includes(x.value)
  //     )
  //   : null;

  // console.log(productToView);
  const RenderEditForm = () => {

    if (fState === 1) {
      return (
        <>
          <div>
            <CContainer>
              <h3>Packaging Level</h3>
              {/* {loading && <CSpinner color="warning" variant="grow" />} */}
              <hr />
              <p>Top level explanation of packaging level</p>
              {rendercard()}
              <CRow>
                <CCol sm={8}>
                  {loadPackaging.map((p) => {
                    return (
                      <CFormCheck
                        key={p.id}
                        type="radio"
                        name="flexRadioDefault"
                        label={p.name}
                        checked={packaginglevel_id == p.id}
                        onChange={() => handlePackaging(p.id)}
                      />
                    );
                  })}
                </CCol>
                <CCol sm={4}>{showThisImage()}</CCol>
              </CRow>
            </CContainer>
          </div>
        </>
      );
    } else if (fState === 2) {
      return (
        <>
          <CRow className="align-items-start">
            <CCol>
              <CFormLabel>Product description</CFormLabel>
              <CFormInput
                name="productdescription"
                defaultValue={
                  productdescription === ""
                    ? productToupdate.productdescription
                    : productdescription
                }
                type="text"
                onBlur={(e) => handleChange(e, "productdescription")}
              />
              <br />
              <CFormLabel htmlFor="">Target market</CFormLabel>
              <Select
                className="dropdown"
                placeholder="Select target market"
                value={market_show == "" ? defaultValue : market_show}
                options={targeted_market} // set list of the data
                onChange={(e) => handleTargetMarket(e)} // assign onChange function
                isMulti
                isClearable
              // name="TargetMarketList"
              />
              <br />
              <CFormLabel>Life span</CFormLabel>
              <CFormInput
                name="lifespan"
                defaultValue={
                  lifespan === "" ? productToupdate?.lifespan : lifespan
                }
                type="number"
                onBlur={(e) => handleChange(e, "lifespan")}
              />
              <br />
              <CFormLabel>Marketing Information</CFormLabel>
              <CFormInput
                name="marketingmessage"
                defaultValue={
                  marketingmessage === ""
                    ? productToupdate.marketingmessage
                    : marketingmessage
                }
                type="text"
                onBlur={(e) => handleChange(e, "marketingmessage")}
              />
              <br />
              <CFormLabel>Storage instruction</CFormLabel>
              <CFormInput
                name="storageinstruction"
                defaultValue={
                  storageinstruction == ""
                    ? productToupdate.storageinstruction
                    : storageinstruction
                }
                type="text"
                onBlur={(e) => handleChange(e, "storageinstruction")}
              />
            </CCol>
            <CCol>
              <CFormLabel htmlFor="">Brand Information</CFormLabel>

              <CFormSelect
                name="brandinformation_id"
                defaultValue={
                  brandinformation_id === "--select--"
                    ? productToupdate.brandinformation_id
                    : brandinformation_id
                }
                options={brandOption}
                onChange={(e) => handleChange(e, "brandinformation_id")}
              />
              <br />

              <CFormLabel htmlFor="">
                Consumer first availability date
              </CFormLabel>
              <CFormInput
                type="date"
                name="dateOfActivation"
                id=""
                defaultValue={
                  consumerfirstavailabilitydate === ""
                    ? useThisDate
                    : consumerfirstavailabilitydate
                }
                onChange={(e) =>
                  handleChange(e, "consumerfirstavailabilitydate")
                }
              />
              <br />
              <CFormLabel htmlFor="">Product category</CFormLabel>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={
                  brickcategory_id == ""
                    ? brickmap.filter(
                      (i) => i.value == productToupdate.brickcategory_id
                    )[0]
                    : brickmap.filter((i) => i.value == brickcategory_id)[0]
                } // set selected values
                options={brickmap} // set list of the data
                onChange={(e) => handleSelect(e, "brickcategory_id")} // assign onChange function
                // isMulti
                name="brickcategory_id"
                isClearable
              />
              <br />

              <CFormLabel htmlFor="">Life span unit</CFormLabel>

              <CFormSelect
                name="lifespanunit"
                defaultValue={
                  lifespanunit === ""
                    ? productToupdate.lifespanunit
                    : lifespanunit
                }
                options={dayOrWeek}
                onChange={(e) => handleChange(e, "lifespanunit")}
              />
              <br />
              <CFormLabel htmlFor="">Units of measurements</CFormLabel>

              <CFormSelect
                name="netcontent_id"
                defaultValue={
                  netcontent_id === ""
                    ? productToupdate.netcontent_id
                    : netcontent_id
                }
                options={unitOption}
                onChange={(e) => handleChange(e, "netcontent_id")}
              />
            </CCol>
            <CCol>
              <CFormLabel htmlFor="">Packaging Type</CFormLabel>
              <CFormSelect
                name="packagingtype_id"
                defaultValue={
                  packagingtype_id === ""
                    ? productToupdate.packagingtype_id
                    : packagingtype_id
                }
                options={ModifiedPackage}
                onChange={(e) => handleChange(e, "packagingtype_id")}
              />
              <br />

              <CFormLabel>Ingredients</CFormLabel>
              <CFormInput
                name="ingredients"
                defaultValue={
                  ingredients === ""
                    ? productToupdate?.ingredients
                    : ingredients
                }
                type="text"
                onBlur={(e) => handleChange(e, "ingredients")}
              />
              <br />
              <CFormLabel>Net weight</CFormLabel>
              <CFormInput
                name="netweight"
                defaultValue={
                  netweight === "" ? productToupdate?.netweight : netweight
                }
                type="number"
                onBlur={(e) => handleChange(e, "netweight")}
              />
              <br />
              <CFormLabel>Instruction of use</CFormLabel>
              <CFormInput
                name="lifespan"
                defaultValue={
                  directionofuse === ""
                    ? productToupdate?.directionofuse
                    : directionofuse
                }
                type="text"
                onBlur={(e) => handleChange(e, "directionofuse")}
              />
            </CCol>
          </CRow>
        </>
      );
    } else if (fState === 3) {
      return (
        <>
          <p>Explanation of product images (size and all)</p>
          <CRow>
            <CCol>
              <img
                src={
                  productToupdate.frontimage == ""
                    ? `${process.env.REACT_APP_BASE_URL_IMAGE}${productToupdate.frontimage}`
                    : show.frnt
                      ? show.frnt
                      : show.showImg
                }
                // src={show.frnt ? show.frnt : dispImg1 ? dispImg1 : show.showImg}
                style={{ height: 270, width: 270, objectFit: "cover" }}
                alt=""
                name="FrontImage"
              />
              <CInputGroup className="mb-3">
                <CFormInput
                  type="file"
                  id="inputGroupFile01"
                  accept="image/*"
                  name="FrontImage"
                  onChange={(e) => saveFrontImage(e, "FrontImage")}
                />
              </CInputGroup>
            </CCol>
            <CCol>
              <img
                src={
                  productToupdate.backimage == ""
                    ? `${process.env.REACT_APP_BASE_URL_IMAGE}${productToupdate.backimage}`
                    : show.bck
                      ? show.bck
                      : show.showImg
                }
                style={{ height: 270, width: 270, objectFit: "cover" }}
                alt=""
              />
              <CInputGroup className="mb-3">
                <CFormInput
                  type="file"
                  id="inputGroupFile01"
                  accept="image/*"
                  name="BackImage"
                  onChange={(e) => saveBackImg(e, "BackImage")}
                />
              </CInputGroup>
            </CCol>
            <CCol>
              <img className="productImage" style={{ height: 270 }} alt="" />
            </CCol>
          </CRow>
        </>
      );
    } else if (fState == 4) {
      return (
        <>
          <CRow className="align-items-start">
            <CCol>
              <CFormLabel>Active Ingredients</CFormLabel>
              <CFormInput
                name="activeIngredients"
                defaultValue={
                  ActiveIngredient == "" ? activeI : ActiveIngredient
                }
                type="text"
                onBlur={(e) => handleChange(e, "ActiveIngredient")}
              />
              <br />
              <CFormLabel>ATC Code</CFormLabel>
              <CFormInput
                name="atcCode"
                defaultValue={atcCode == "" ? atcC : atcCode}
                type="text"
                onBlur={(e) => handleChange(e, "atcCode")}
              />
              <br />

              <CFormLabel>Direction of use</CFormLabel>
              <CFormInput
                name="lifespan"
                defaultValue={directionOfUse == "" ? dirOfUse : directionOfUse}
                type="text"
                onBlur={(e) => handleChange(e, "directionOfUse")}
              />
              <br />
              <CFormLabel>Functional name</CFormLabel>
              <CFormInput
                name="marketingmessage"
                defaultValue={functionalName == "" ? funcN : functionalName}
                type="text"
                onBlur={(e) => handleChange(e, "functionalName")}
              />
              <br />
              <CFormLabel htmlFor="">Manufacturer</CFormLabel>

              <CFormInput
                name="storageinstruction"
                defaultValue={
                  manufacturer == ""
                    ? manuf
                    : manufacturer
                }
                type="text"
                onBlur={(e) => handleChange(e, "manufacturer")}
              />
              <br />
            </CCol>
            <CCol>
              <CFormLabel>Pack size</CFormLabel>
              <CFormInput
                name="storageinstruction"
                defaultValue={packSize == "" ? pacS : packSize}
                type="text"
                onBlur={(e) => handleChange(e, "packSize")}
              />
              <br />
              <CFormLabel>Route of administration</CFormLabel>
              <CFormInput
                name="storageinstruction"
                defaultValue={
                  routeOfAdministration == "" ? routeOfA : routeOfAdministration
                }
                type="text"
                onBlur={(e) => handleChange(e, "routeOfAdministration")}
              />
              <br />

              <CFormLabel>Generic name</CFormLabel>
              <CFormInput
                name="storageinstruction"
                defaultValue={genericName == "" ? genericN : genericName}
                type="text"
                onBlur={(e) => handleChange(e, "genericName")}
              />
              <br />
              <CFormLabel>Product form</CFormLabel>
              <CFormInput
                name="storageinstruction"
                defaultValue={productForm == "" ? pForm : productForm}
                type="text"
                onBlur={(e) => handleChange(e, "productForm")}
              />
              <br />
              <CFormLabel htmlFor="">Manufacturer Address</CFormLabel>

              <CFormInput
                name="storageinstruction"
                defaultValue={
                  manufacturerAddress == ""
                    ? manufAddr
                    : manufacturerAddress
                }
                type="text"
                onBlur={(e) => handleChange(e, "manufacturerAddress")}
              />
              <br />
            </CCol>
            <CCol>
              <CFormLabel htmlFor="">TradeItem description</CFormLabel>

              <CFormInput
                name="storageinstruction"
                defaultValue={
                  tradeItemDescription == ""
                    ? tradeItemDes
                    : tradeItemDescription
                }
                type="text"
                onBlur={(e) => handleChange(e, "tradeItemDescription")}
              />
              <br />

              <CFormLabel>Strength</CFormLabel>
              <CFormInput
                name="strength"
                defaultValue={strength == "" ? ProdStrength : strength}
                type="text"
                onBlur={(e) => handleChange(e, "strength")}
              />
              <br />
              <CFormLabel>Shelf life (in month(s))</CFormLabel>
              <CFormInput
                name="netweight"
                defaultValue={shelfLife == "" ? shelfL : shelfLife}
                type="number"
                onBlur={(e) => handleChange(e, "shelfLife")}
              />
              <br />
              <CFormLabel>Quantity</CFormLabel>
              <CFormInput
                name="lifespan"
                defaultValue={quantity == "" ? pharmQty : quantity}
                type="text"
                onBlur={(e) => handleChange(e, "quantity")}
              />
            </CCol>

          </CRow>
        </>
      );
    }
  };

  // console.log("Hello every one", productToView);
  const myproducts = products.map((i) => {
    // console.log("i", i);
    try {
      const maped = {};
      maped.Gtin = i.gtin;
      maped.Description = i.productdescription;
      maped.BrandName = i.brandname;
      maped.UnitOfMeasure = i.netContent.name;
      maped.NetWeight = i.netweight;
      maped.DirectionOfUse = i.directionofuse;
      maped.BrandName = i.brandname;
      maped.ProductCategory = i.brickCategory.brick;
      maped.StorageInstruction = i.storageinstruction;
      maped.NafdacNumber = i.nafdacnumber;
      maped.LifeSpan = i.lifespan + " " + i.lifespanunit;
      // maped.BrandName=i.brandname,
      // maped.BrandName=i.brandname

      return maped;

    } catch (error) {
      console.log(i.gtin)
      console.log(error)
    }
  });
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
  return (

    <div className="my-3 mx-3 border-t-2 border-gs1-blue">
       <CModal visible={qrmodal} onClose={() => setQRModal(false)}>
          <CModalHeader onClose={() => setQRModal(false)}>
            <CModalTitle className="d-flex justify-content-between"><span>GS1 Producr QR Download</span></CModalTitle>
          </CModalHeader>
          <CModalBody className="d-flex justify-content-center">
            {
            // qrCodeRef != null ?
              <div className="d-flex justify-content-center flex-column">
                <div className="d-flex justify-content-center">
                <img width={100} src={qrdata}  alt="QR Code" />
                {/* <img width={100} src="{qrdata}" alt="QR Code" /> */}

                 
                </div>

                <h5 className="mt-4 text-decoration-underline g1-gold fw-bold">PLEASE NOTE!</h5>
                <p>randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dicti</p>
              </div>


              
              }

          </CModalBody>
          <CModalFooter className="d-flex justify-content-between">
            <div>
              <span>{qrgtin}</span>
            </div>
            <div>
              <CButton className="mx-2" color="secondary" onClick={() => setQRModal(false)}>
                Close
              </CButton>
              {/* <a href=`${downloadBarcode}` download></a> */}
              <CButton color="primary" onClick={() => downloadQRCode()}>
                Get QR
              </CButton>
            </div>
          </CModalFooter>
        </CModal>


      <CModal visible={visibleM} onClose={() => setVisibleM(false)}>
        <CModalHeader onClose={() => setVisibleM(false)}>
          <CModalTitle>Download barcode Image</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <img src={showBarcode} alt="" style={{ height: "120px" }} />
          {/* <BarcodeGenerator /> */}
        </CModalBody>
        <CModalFooter className="d-flex justify-content-between">
          <div>
            <CFormCheck checked={format === '2'} onChange={handleFormatRadioChange} inline type="radio" name="inlineRadioOptions" id="inlineCheckbox1" value="2" label="WMF" />
            <CFormCheck checked={format === '0'} onChange={handleFormatRadioChange} inline type="radio" name="inlineRadioOptions" id="inlineCheckbox2" value="0" label="PNG" />
          </div>
          <div>
            <CButton className="mx-2" color="secondary" onClick={() => setVisibleM(false)}>
              Close
            </CButton>
            {/* <a href=`${downloadBarcode}` download></a> */}
            <CButton color="primary" onClick={() => handleDownload(gtinfetch)}>
              download
            </CButton>
          </div>
        </CModalFooter>
      </CModal>
      {/* Product upadate details */}
      <CModal
        size="lg"
        visible={visibleMM}
        onClose={() => {
          setVisibleMM(false);
          setfState(1);
        }}
      >
        <CModalHeader
          onClose={() => {
            setVisibleMM(false);
            setfState(1);
          }}
        >
          <CModalTitle>
            Edit product information for {productToupdate?.productdescription}
            <br />
            <CFormLabel htmlFor="" style={{ color: "#1565c0" }}>
              *Toggle to edit Healthcare attribute (Healthcare product only)*
            </CFormLabel>
            <CFormSwitch
              defaultValue={false}
              onClick={listAsHealthCare}
              size="xl"
              id="formSwitchCheckDefaultXL"
            />
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <RenderEditForm />
        </CModalBody>
        <CModalFooter>
          {fState !== 1 ? (
            <CButton color="secondary" onClick={() => setfState(fState - 1)}>
              back
            </CButton>
          ) : (
            <CButton color="secondary" onClick={() => setVisibleMM(false)}>
              Close
            </CButton>
          )}
          {/* fState === 3 && productToupdate.isPharma */}
          {fState === 3 && manualPharma == false ? (
            <CButton color="primary" onClick={saveEditedInfo}>
              {loading && (
                <CSpinner component="span" size="sm" aria-hidden="true" />
              )}
              Save changes
            </CButton>
          ) : fState === 4 ? (
            <CButton color="primary" onClick={saveEditedInfo}>
              {loading && (
                <CSpinner component="span" size="sm" aria-hidden="true" />
              )}
              Save changes
            </CButton>
          ) : (
            <CButton color="primary" onClick={() => setfState(fState + 1)}>
              Next
            </CButton>
          )}
        </CModalFooter>
      </CModal>
      {/* View product info modal */}
      <CModal
        ref={targetRef}

        // className="border-t-2 border-gs1-blue"
        size="lg"
        visible={visibleMMM}
        onClose={() => {
          setVisibleMMM(false);
          // setfState(1);
        }}
      >
        <CModalHeader
          onClose={() => {
            setVisibleMMM(false);
            // setfState(1);
          }}
        >
          <CModalTitle>
            <b>Product Information</b>

            <br />
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <img
            className="h-[100px] rounded-md my-3"
            src={
              productToView.frontimage
                ? `${process.env.REACT_APP_BASE_URL_IMAGE}${productToView.frontimage}`
                : temp_image
            }
            alt=""
            srcset=""
          />
          <b>Product Description:</b> {productToView?.productdescription}
          <hr />
          <div className="columns-2">
            <div><b>Brand name:</b> {productToView.brandname}</div>
            <div><b>Brick category:</b> {productToView?.brickCategory?.brick}</div>
          </div>
          <hr />
          <div className="columns-2">
            <div><b>GTIN:</b> {productToView?.gtin}</div>
            <div><b>Packaging Type:</b>{productToView?.packagingType?.name}</div>
          </div>
          <hr />
          <div className="columns-2">
            <div><b>Packaging level:</b> {productToView?.packageLevel?.name}</div>
            {/* <div>Packaging Type: {productToView?.packagingType?.name}</div> */}
          </div>
          {productToView?.pharmaceuticalInformation != null &&
            <div>
              <hr />
              <div className="px-1 py-1 my-3" style={{ backgroundColor: '#002C6C', color: '#ffffff', fontWeight: 'bold' }} >Advanced Pharmaceutical Information</div>
              <div style={{ borderBottom: 'dashed 1px gray' }} className="columns-2 pb-2 mb-2">
                <div><b>GenericName:</b> {productToView?.pharmaceuticalInformation?.genericName}</div>
                <div><b>FunctionalName:</b>{productToView?.pharmaceuticalInformation?.functionalName}</div>
              </div>
              <div style={{ borderBottom: 'dashed 1px gray' }} className="columns-2 pb-2 mb-2">
                <div><b>TradeItemDescription:</b> {productToView?.pharmaceuticalInformation?.tradeItemDescription}</div>
                <div><b>Strength:</b>{productToView?.pharmaceuticalInformation?.strength}</div>
              </div>
              <div style={{ borderBottom: 'dashed 1px gray' }} className="columns-2 pb-2 mb-2">
                <div><b>ProductForm:</b> {productToView?.pharmaceuticalInformation?.productForm}</div>
                <div><b>RouteOfAdministration:</b>{productToView?.pharmaceuticalInformation?.routeOfAdministration}</div>
              </div>
              <div style={{ borderBottom: 'dashed 1px gray' }} className="columns-2 pb-2 mb-2">
                <div><b>AtcCode:</b> {productToView?.pharmaceuticalInformation?.atcCode}</div>
                <div><b>PackSize:</b>{productToView?.pharmaceuticalInformation?.packSize}</div>
              </div>
              <div style={{ borderBottom: 'dashed 1px gray' }} className="columns-2 pb-2 mb-2">
                <div><b>Quantity:</b> {productToView?.pharmaceuticalInformation?.quantity}</div>
                <div><b>ActiveIngredient:</b>{productToView?.pharmaceuticalInformation?.activeIngredient}</div>
              </div>
              <div style={{ borderBottom: 'dashed 1px gray' }} className="columns-2 pb-2 mb-2">
                <div><b>PrimaryPackagingMaterial:</b> {productToView?.pharmaceuticalInformation?.primaryPackagingMaterial}</div>
                <div><b>DirectionOfUse:</b>{productToView?.pharmaceuticalInformation?.directionOfUse}</div>
              </div>
              <div className="columns-2 pb-2 mb-2">
                <div><b>Manufacturer:</b> {productToView?.pharmaceuticalInformation?.manufacturer}</div>
                <div><b>ManufacturerAddress:</b>{productToView?.pharmaceuticalInformation?.manufacturerAddress}</div>
              </div>


            </div>

          }
          {/* <RenderEditForm /> */}
        </CModalBody>
        <CModalFooter>
          {/* <CButton color="primary" onClick={() => toPDF()}>
            Download PDF
          </CButton> */}
          <CButton color="secondary" onClick={() => setVisibleMMM(false)}>
            Close
          </CButton>

          {/* fState === 3 && productToupdate.isPharma */}
          {/* {fState === 3 && manualPharma == false ? (
              <CButton color="primary" onClick={saveEditedInfo}>
                {loading && (
                  <CSpinner component="span" size="sm" aria-hidden="true" />
                )}
                Save changes
              </CButton>
            ) : fState === 4 ? (
              <CButton color="primary" onClick={saveEditedInfo}>
                {loading && (
                  <CSpinner component="span" size="sm" aria-hidden="true" />
                )}
                Save changes
              </CButton>
            ) : (
              <CButton color="primary" onClick={() => setfState(fState + 1)}>
                Next
              </CButton>
            )} */}
        </CModalFooter>
      </CModal>

      <CCard className="">
        <CCardBody className="gtin-card">
          <h3 className="">Listed products</h3>
          <hr />
          <GenerateExcel eData={myproducts} name="Products information" />
          <CRow>
            <div className="tablewrap overflow-scroll">
              <CSmartTable
                activePage={1}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                items={products}
                itemsPerPageSelect
                itemsPerPage={5}
                pagination
                scopedColumns={{
                  frontimage: (product) => (
                    <td>
                      <img
                        className="rounded-md"
                        src={
                          product.frontimage
                            ? `${process.env.REACT_APP_BASE_URL_IMAGE}${product.frontimage}`
                            : temp_image
                        }
                        height={60}
                        width={40}
                      />
                    </td>
                  ),
                  brickCategory: (products) => (
                    <td>{products.brickCategory.brick}</td>
                  ),
                  // dateoflisting: (products) =>
                  //   formatTime(products.dateoflisting),
                  iscompleted: (products) => (
                    <td>{fetchFaceEmoji(products.iscompleted)}</td>
                  ),
                  generate_barcode: (products) => (
                    <td>
                      {
                        <ImBarcode
                          size={20}
                          onClick={() => getImages(products.gtin)}
                        />
                      }
                    </td>
                  ),
                  edit_products: (products) => (
                    <td>
                      {<BiEdit size={20} onClick={() => callUpdate(products)} />}
                    </td>
                  ),
                  view_info: (products) => (
                    <td>
                      {<MdPreview size={20} onClick={() => viewInfo(products)} />}
                    </td>
                  ),
                  download_qr: (products) => (
                    <td>
                      {
                        <ImQrcode
                          size={20}
                          onClick={() => getQR(products.gtin)}
                        />
                      }
                    </td>
                  ),
                }}
                sorterValue={{ column: "name", state: "asc" }}
                tableFilter
                tableHeadProps={{
                  color: "",
                }}
                tableProps={{
                  striped: false,
                  bordered: false,
                  hover: true,
                }}
              />
            </div>
          </CRow>{" "}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default ViewProducts;
